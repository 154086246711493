import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo.png'

const Header = () => {
    return (
        <div className='py-2 flex justify-between'>
            <div className='flex items-center lg:pl-36 pl-5'>
                <img className='lg:w-2/12 md:w-2/12 w-1/6' src={logo} alt="logo" />
                
                <div className='w-fit px-2'>
                    <p className='text-2xl font-black'><Link>আনন্দ মার্গ প্রচারক সংঘ</Link></p>
                    <p>আত্ম মোক্ষার্তম জগৎ হিতায়চ </p>
                </div>
            </div>
            <div className='pr-12'>
                <Link to="login">Loging</Link>
            </div>
        </div>
    );
};

export default Header;