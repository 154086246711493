import { createBrowserRouter } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import AnandaMagrabd from "./pages/about/AnandaMagrabd";
import Establishment from "./pages/about/Establishment";
import Goal from "./pages/about/Goal";
import Amrt from "./pages/activities/Amrt";
import ChildCare from "./pages/activities/ChildCare";
import Education from "./pages/activities/Education";
import Rurawa from "../components/pages/activities/Rurawa";
import Yoga from "./pages/activities/Yoga";
import Contact from "./pages/Contact";
import Home from "./pages/HomePage/Home";
import PhilosophySpiritual from "./pages/philosophy/PhilosophySpiritual";
import Prout from "./pages/philosophy/Prout";
import SocialPhilosophy from "./pages/philosophy/SocialPhilosophy";


const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { index: true, element : <Home/>  },
            { path:'/home', element : <Home/> },
            { path:'/establishment', element : <Establishment/> },
            { path:'/ampsbd', element : <AnandaMagrabd /> },
            { path:'/goal', element : <Goal /> },
            { path:'/yoga', element :<Yoga/> },
            { path:'/education', element :<Education/> },
            { path:'/childrenhomes', element :<ChildCare/> },
            { path:'/amrt', element :<Amrt/> },
            { path:'/rurawa', element : <Rurawa />},
            { path:'/prout', element :<Prout/>},
            { path:'/social', element :<SocialPhilosophy/> },
            { path:'/spiritual', element :<PhilosophySpiritual/>},
            { path:'/contact', element :<Contact /> }
        ]
    },
    {

    }
])

export default router;