import React from "react";
import Contacts from "./contact.json";

const Contact = () => {
  return (
    <div className="container mx-auto my-10">
          {Contacts.map((contact, id) => {
            return (
              <div key={id} className="grid grid-cols-12 border align-top md:leading-10 hover:odd:bg-gray-300"> 
                <div className="text-center px-3 col-span-1 md:col-span-2 font-bold rounded-full">{id + 1}</div>
                <div className="text-left px-3 col-span-10 md:col-span-4">{contact.name}</div>
                <div className="text-left px-3 col-span-10 md:col-span-6"> <b>{contact.designation}</b> <br /> {contact.address} <br />{contact.contact}</div>
              </div>

            );
          })}
          
    </div>
  );
};

export default Contact;
