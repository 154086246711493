import React from "react";
import Slider from "./Slider";
import HomeAbout from "./HomeAbout";
import HomeActivities from "./HomeActivities";
import HomePhilosophy from "./HomePhilosophy";

const Home = () => {
  return (
    <div className="min-h-screen container flex flex-col items-center mx-auto py-10">
      <Slider />
      <HomeAbout />
      <HomeActivities />
      <HomePhilosophy />
    </div>
  );
};

export default Home;
