/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import NavLinks from "./data.json";
import "../style/Navber.css";
import { FaBars, FaWindowClose} from "react-icons/fa";

const Navber = () => {
  const [toggler, setToggler] = useState(false);
  const [mouseLeave, setMouseLeave] = useState(false);
  const [dropdown, setDropwodn] = useState(false);

  return (
    <div className=" flex">
      <div className="flex-1 w-auto flex-row  bg-orange-400">
        <span onClick={() => setToggler(!toggler)} className="text-2xl md:hidden text-white block ml-3 cursor-pointer">
          {toggler ? <FaWindowClose /> : <FaBars />}
        </span>
        <ul className={`flex-row justify-center md:flex text-start ${toggler ? "" : "hidden"}`}>
          {NavLinks.map((Slink, i) => {
            if (!Slink.children) {
              return (
                <li key={i} className="header-nav-link py-5 text-start" onClick={() => setToggler(!toggler)}>
                  <NavLink to={Slink.path} className="active-link text-white">
                    {Slink.title}
                  </NavLink>
                </li>
              );
            }
            return (
              <li onClick={() => setDropwodn(!dropdown)} onMouseLeave={() => setMouseLeave(!mouseLeave)} key={Slink.id} className="header-nav-link relative group ">
                <div className="py-4" >
                  <NavLink to={Slink.path} className="active-link flex text-white">
                    {Slink.title} 
                  </NavLink>
                </div>
                <ul  className={`bg-orange-400 w-64 pl-3 md:absolute md:my-5 top-10 hidden pb-4 md:z-10 ${dropdown ? "block" : "flex flex-col group-hover:flex gap-y-3"}`}

                >
                  {Slink.children.map((chlLink, id) => {
                    if (!chlLink.children) {
                      return (
                        <li key={chlLink.id}  onClick={() => setToggler(!toggler)}>
                          <NavLink to={chlLink.path} className="active-link text-zinc-700" >
                            {chlLink.title}
                          </NavLink>
                        </li>
                      );
                    }
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Navber;
