import React from "react";
import Yoga1 from '../../../img/Activities/image001.jpg'
import Yoga2 from '../../../img/Activities/image002.jpg'
import Yoga3 from '../../../img/Activities/image003.jpg'
import Yoga4 from '../../../img/Activities/image004.jpg'
import Yoga5 from '../../../img/Activities/image005.jpg'
import Yoga6 from '../../../img/Activities/image006.jpg'
const Yoga = () => {
  return (
    <div className="container mx-auto text-justify my-6 text-xl leading-8 p-2">
      <div className="text-center">
        <h1 className="text-2xl font-semibold">আনন্দমার্গ যোগ- এক সন্তুলিত আদর্শ জীবনধারা</h1> <br />
        <h2 className="text-xl font-semibold md:my-3">
          নিয়মিত যোগ অভ্যাস করুন <br />
          সুস্থ্য থাকুন, শান্তিতে থাকুন, আনন্দ লাভ করুন।
        </h2>
        <hr className="w-2/4 mx-auto" />
      </div>
      <br />
      <div className="clear-both lg:flex flex-row">
        <div className="basis-3/6">
          <strong>যোগ কী? </strong> <br />
          যোগ মূলতঃ অত্যন্ত সূক্ষ্ম বিজ্ঞানের ওপর আধারিত একটি আধ্যাত্মিক জীবনধারা যা শরীর, মন ও আত্মার সমন্বয় আনতে গুরুত্ব দেয়। মানুষের অস্তিত্ব ত্রি-ভৌমিক- অর্থাৎ শরীর, মন
          ও আত্মা এই তিনকে নিয়ে মানুষের জীবন। তাই জীবনকে যথার্থ আনন্দময় করে গড়ে তুলতে হলে দৈহিক, মানসিক ও আত্মিক – এই তিনেরই উন্নতি একান্ত প্রয়োজন। আমাদের দৈহিক সুস্থতা ও
          মানসিক শক্তি অর্জন করতে হবে আর আধ্যাত্মিক উন্নতিও ঘটাতে হবে। যোগসাধনা মানুষের এই সার্বিক উন্নতি ঘটায়। তাই যোগ হলো দৈহিক সুস্থ্যতা, মানসিক শান্তি তথা আধ্যাত্মিক
          আনন্দ লাভের এক কলাকৌশল তথা ফলিত বিজ্ঞান। <br />
          <br />
          আবার আধ্যাত্মিক উন্নতি করতে হলে নৈতিক-সামাজিক আচরণ বিধিও মেনে চলতে হবে। এই নৈতিকতা ব্যষ্টি তথা সমাজ জীবনে চলার প্রারম্ভিক বিন্দু আর অন্তর্মূখী মানস-আধ্যাত্মিক যোগ
          সাধনার দ্বারা আত্মোপলব্ধির মাধ্যমে পরমানন্দ লাভ করাই মানব জীবনের লক্ষ্য। তাই আনন্দমার্গ যোগ হলো এক সন্তুলিত আদর্শ জীবনধারা।
        </div>

        <div className="basis-3/6">
          <img src={Yoga1} alt="Yoga one" className="md:w-3/4 lg:w-4/6 lg:pt-8 mx-auto"/>
        </div>
      </div>
      

      <h1 className="my-3">
        <b>আনন্দমার্গ যোগের বৈশিষ্ট্যঃ</b>
      </h1>
      <ul className="list-inside list-disc">
        <li>শারীরিক সুস্থ্যতা</li>
        <li>মানসিক বিকাশ</li>
        <li>সামাজিক সচেতনতা</li>
        <li>নৈতিক মূল্যবোধের জাগরণ ও</li>
        <li>আধ্যাত্মিক আনন্দ লাভ।</li>
      </ul>
      <br />
      <div className="clear-both lg:flex flex-row">
      
        <div className="basis-3/6 lg:basis-4/6">
          <strong>দৈহিক সুস্থতাঃ </strong> <br />
          দৈহিক সুস্থতা মানে বিভিন্ন রোগ থেকে দেহকে মুক্ত রাখা। বলা হয়- “Prevention is better than cure” অর্থাৎ রোগ হওয়ার পর চিকিৎসা করার চেয়ে রোগের প্রতিরোধের ব্যবস্থা
          গ্রহণই শ্রেয়। বিভিন্ন রোগ শরীরে বাসা বাঁধলে আমরা চিকিৎসকের কাছে যাই- দামী দামী ঔষধ খাই। চিকিৎসা বিজ্ঞানীরা বলেন, এই সব ঔষধের পার্শ্ব প্রতিক্রিয়াতেও আবার নোতুন
          নোতুন রোগের সৃষ্টি হয়। কিন্তু নিয়মিত যোগাসন করলে অধিকাংশ ক্ষেত্রে আমরা বিভিন্ন রোগ থেকে দূরে থাকতে পারি তথা সারিয়ে তুলতে পারি।
        </div>

        <div className="basis-3/6 lg:basis-2/6">
          <img src={Yoga2} alt="Yoga Two" className="w-full md:w-3/4 lg:w-5/6 xl:w-3/6 lg:pt-8 mx-auto"/>
        </div>
        
      </div>

    <br />
      <div className="clear-both lg:flex flex-row">
        <div className="basis-3/6 lg:basis-4/6">
          <strong>ষ্ট্রেস থেকে মুক্তিঃ </strong> <br />
          আমাদের জীবনধারার প্রতি মুহুর্ত্তে আমরা মানসিক চাপের (stress) শিকার হই। মানসিক চাপ বৃদ্ধি পেলে সব সময় অশান্তি, খিটখিটে মেজাজ, মাথাধরা প্রভৃতি দেখা দেয়। ক্রমাগত এই
        মানসিক চাপ থেকে হৃদরোগ, আলসার, হাঁপানী প্রভৃতি রোগেরও সৃষ্টি হয়। শুধু এই রোগগুলিই নয়, আধুনিক চিকিৎসা বিজ্ঞানীদের মতে অধিকাংশ রোগেরই উৎস এই মানসিক চাপ। এই মানসিক
        চাপ থেকে সাময়িক ভাবে রেহাই পেতে মানুষ সাধারণতঃ ধুমপান, মদ, হিরোইন, ইয়াবা প্রভৃতি মারাত্মক নেশার দিকে ঝোঁকে। আর এর ফলে মানুষ ক্যানসার প্রভৃতি জটিল ও দুরারোগ্য
        ব্যাধির কবলে পড়ে। এই মানসিক চাপ থেকে মুক্তিরও একমাত্র উপায় হ’ল নিয়মিত যোগ অভ্যাস করা। বিশ্বের খ্যাতনামা চিকিৎসাবিদরাও এই মত পোষণ করেন। 
        </div>

        <div className="basis-3/6 lg:basis-2/6">
          <img src={Yoga3} alt="Yoga Two" className="w-full md:w-3/4 lg:w-5/6 xl:w-3/6 lg:pt-8 mx-auto"/>
        </div>
      </div>

    <br />
      <div className="clear-both lg:flex flex-row">
        <div className="basis-3/6 lg:basis-4/6">
          <strong>মানসিক একাগ্রতা তথা বিকাশঃ </strong> <br />
          বিক্ষিপ্ত সূর্য রশ্মিকে আতশী কাঁচের সাহায্যে কেন্দ্রীভূত করলে যেমন আগুন জ্বলে ওঠে, তেমনি যোগের সাহায্যে মনকে একাগ্র করলে মনের মধ্যে অপরিসীম শক্তির জাগরণ ঘটে। এই
        শক্তিকে শুভপথে লাগালে আমরা জীবনে অনেক বড় কাজ করতে পারি ও সহজেই অভীষ্ট সিদ্ধ করতে পারি। যারা পড়াশুণা করে তারা নিয়মিত যোগ অভ্যাস করলে মানসিক একাগ্রতা বৃদ্ধি পাবে,
        বুদ্ধি তীক্ষ্ণ হবে ও স্মৃতিশক্তিও বৃদ্ধি পাবে। আবার আমরা ভাল-মন্দ যা কিছু করি, প্রকৃতপক্ষে মনই এই সমস্ত কাজ করে। মন যদি শুভবুদ্ধিসম্পন্ন হয়, তাহলে সমস্ত কাজও ভাল
        হবে। <br />
        মন মস্তিষ্ক, সুষুম্নাকাণ্ড, বিভিন্ন গ্রন্থি, স্নায়ুকোষ, স্নায়ুতন্ত্র, ইন্দ্রিয়সমূহ তথা শরীরের বিভিন্ন অঙ্গপ্রত্যঙ্গের মাধ্যমে সমস্ত কাজ করে। আমাদের মন কতগুলি
        বৃত্তির সমাহার। এগুলির সংখ্যা প্রধানতঃ ৫০টি। এগুলি মস্তিষ্কে ও সুষুম্নাকাণ্ডের মধ্যে বিভিন্ন চক্রে অবস্থিত বিভিন্ন গ্রন্থিগুলির দ্বারা নিয়ন্ত্রিত হয়। যেমন, লজ্জা,
        ঘৃণা, ভয়, লোভ, ক্রোধ, আশা, চিন্তা, চেষ্টা, বুদ্ধি, বিবেক প্রভৃতি। সাধারণতঃ মানুষের মধ্যে হীন বৃত্তিগুলি প্রবল ও উন্নত বৃত্তিগুলি অস্ফুট থাকে। যোগের যম-নিয়ম, আসন,
        প্রাণায়াম, ধ্যান প্রভৃতি প্রক্রিয়ার অনুশীলনের সাহায্যে বৃত্তিগুলিকে নিয়ন্ত্রণ করে মনের বিকাশ ঘটানো যায়। বৃত্তিগুলিকে যতই আমরা নিয়ন্ত্রণ করবো ততই মনের একাগ্রতা
        বৃদ্ধি পাবে, ততই মনের শক্তি বৃদ্ধি পাবে, ক্ষুদ্রতা-সঙ্কীর্ণতার গণ্ডী পেরিয়ে মনের বিকাশ হবে। <br />
        </div>

        <div className="basis-3/6 lg:basis-2/6">
          <img src={Yoga4} alt="Yoga Two" className="w-full md:w-3/4 lg:w-full xl:w-4/6 lg:px-5 lg:pt-8 mx-auto"/>
        </div>
      </div>

      <br />

      <div className="clear-both lg:flex flex-row">
        <div className="basis-3/6 lg:basis-4/6">
          
        আমরা মনের যে অংশ নিয়ে প্রাত্যহিক কাজকারবার করি তা কিন্তু আমাদের মনের অতি ক্ষুদ্র অংশ। আমাদের মনের অতি বৃহৎ অংশ - যেখানে রয়েছে বিপুল সম্পদ, ঐশ্ব্যর্য- তা সাধারণতঃ
        আমাদের অজ্ঞাতই থেকে যায়। <br />
        আমাদের মনের কয়েকটি স্তর রয়েছে। এগুলিকে বলা হয় কোষ। এই কোষগুলি হ’ল- কামময় কোষ, মনোময় কোষ, অতিমানস কোষ, বিজ্ঞানময় কোষ ও হিরন্ময় কোষ। কামময় কোষ হ’ল মনের স্থুল অংশ
        যাতে রয়েছে মনের স্থূল কামনা-বাসনা। তাই এই মনকে স্থূলমন বলা হয়। এই মন ইন্দ্রিয় সমূহের মাধ্যমে বহির্জগতের সঙ্গে সম্পর্কিত হয়। এরপর মনের যে সূক্ষ্মতর স্তর আছে – তা
        হ’ল মনোময় কোষ। এই মনকে সূক্ষ্মমনও বলা হয়। গভীর ভাবে চিন্তন, মনন, গবেষণা প্রভৃতি মনের এই স্তরে করা হয়। অর্থাৎ যারা জ্ঞানী, কবি, লেখক, বিজ্ঞানী তাঁদের এই মনোময় কোষ
        জাগ্রত। <br />
        এরপরে রয়েছে অতিমানস, তারপর বিজ্ঞানময় ও তারপর মনের সূক্ষ্মতম কোষ- হিরন্ময় কোষ। অতিমানস কোষে আধ্যাত্মিক চিন্তাভাবনার স্ফুরণ হয়। বিজ্ঞানময় কোষ জাগলে বিবেক সদাজাগ্রত
        হয়। তখন মানুষ সব সময় শুভ চিন্তা নিয়ে থাকে ও গভীর আধ্যাত্মিক অনুরাগ জাগে। হিরন্ময় কোষ জাগ্রত হলে মানুষ আধ্যাত্মিক স্তরের পরমানন্দে ডুবে থাকতে পারে। <br />
        হিরন্ময় কোষের ঊর্ধ্বে উঠলে মন আত্মাতে সমাহিত হয়। তখন তাঁর পূর্ণ আত্মোপলদ্ধি হয়। মন আত্মাতে সমাহিত হলে জীবাত্মার সঙ্গে পরমাত্মার মিলন ঘটে। ‘সংযোগো যোগ ইত্যুক্তো
        জীবাত্মা-পরমাত্মনঃ’- অর্থাৎ জীবাত্মার সাথে পরমাত্মার এই সংযোগই যোগের মূল কথা।
        </div>

        <div className="basis-3/6 lg:basis-2/6">
          <img src={Yoga5} alt="Yoga Two" className="w-full md:w-3/4 lg:w-fit xl:w-4/6 lg:pt-8 mx-auto"/>
        </div>
      </div>
  
      <br />  

      <p className="text-justify leading-9">
      
        <b>আনন্দ লাভের প্রয়াসঃ</b> <br />
        <p className="indent-16">
        “সুখানুরক্তি পরমা জৈবীবৃত্তিঃ”। প্রতিটি জীবই সুখ চায়, প্রতিটি মানুষ সুখ চায়। কোন কিছুর অভাবই দুঃখ আর অভাবের পূর্ত্তিই হ’ল সুখ। জাগতিক বস্তু সীমিত আর আমাদের দেহের প্রয়োজনও সীমিত। তাই জাগতিক বস্তুর প্রাপ্তিতে সুখও সীমিত অর্থাৎ অল্প সময়ের জন্য পাওয়া যায়। কিন্তু মানুষের মনের ক্ষুধা অনন্ত। তার দেহের ক্ষুধা মিটলেও মনের ক্ষুধা মেটে না। সে অনন্ত সুখ পেতে চায়। অল্প সুখ পেলে যেন কিছুতেই তার মন ভরে না। অল্প সুখ পেতে পেতেই ফুরিয়ে যায়। একটা ছোট সুস্বাদু জিনিস জিবে আসতে না আসতেই জিবের নীচে চলে যায়। সুতরাং আর একটু থাকলে ভাল হত এই ভাবনা তাকে কষ্ট দিতে থাকে। <br />

        তাই সত্যি যদি আনন্দ পেতে হয় তাহলে এমন জিনিস পেতে হবে যার আগা-গোড়া নেই, যার থেকে সুখ পেয়ে চলেছি------পেয়েই চলেছি-----আর শেষ হচ্ছে না। এই অনন্ত সুখই আনন্দ, আর আনন্দই ব্রহ্ম। মন যখন বিশেষ প্রচেষ্টার দ্বারা আত্মাভিমুখী হয়ে বিস্তার লাভ করে, আত্মিক অনুভূতি লাভ করে সে পায় আনন্দ। আর তখনই কেবল আমাদের অতৃপ্ত মন তৃপ্ত হতে পারে।
        তাই প্রতিটি মানুষ জ্ঞাতসারে হোক বা অজ্ঞাতসারেই হোক সেই আনন্দঘনসত্তা ব্রহ্মকে পেতে চায়। আর এঁকে উপলব্ধি করার যে বিধিবদ্ধ আন্তরিক প্রয়াস তাকেই বলে যোগ সাধনা। যোগ মানে মিলন। আমি পরমপুরুষকে পেতে চাই। তাঁকে পেয়ে পরম আনন্দে তাঁর ভাবেই সমাহিত হতে চাই। এই ভাবে পরমপুরুষে সমাহিত হয়ে তাঁর আনন্দে বিভোর হয়ে থাকাই যোগ সাধনার মূল উদ্দেশ্য। এটাই মানব জীবনের লক্ষ্য। <br />

        আর শান্তি হ’ল মানসিক সাম্যাবস্থা। যখন আমাদের মন ব্যাষ্টিগত বা পারিবারিক বা পারিপার্শ্বিক যেমন, সামাজিক-অর্থনৈতিক-রাজনৈতিক-সাম্প্রদায়িক ইত্যাদি কোন প্রকার সমস্যার
        দ্বারা প্রভাবিত হয়না, তখনকার মানসিক সাম্যাবস্থাই মনের শান্তি। আর যখন কোন কারণে এই মানসিক সাম্য বিঘ্নিত হয়, মানসিক চাপ সৃষ্টি হয়, তখন মনের শান্তিও বিঘ্নিত হয়।
        </p>
       
        <br /> 
        <b>নৈতিক মূল্যবোধের জাগরণ করাঃ</b> <br />

        <p className="indent-16">
        আজকের সমাজে সবচেয়ে বড় সংকট হ’ল নৈতিকতার সংকট। মানুষের নৈতিক-সামাজিক মূল্যবোধের অবক্ষয়ের কারণেই সমাজে এত অবিচার-অত্যাচার-শোষণ। যোগেশ্বর শ্রীশ্রীআনন্দমূর্ত্তিজী
        বলেছেন, “আদর্শ সমাজ-ব্যবস্থা নির্ভর করে সদস্যদের পারস্পরিক সহযোগিতা ও সামবায়িক আচরণের ওপর, আর এই সামবায়িক আচরণ আবার যম-নিয়মের সাধনার ওপর নির্ভরশীল। তাই আধ্যাত্মিক সাধনা, বিশেষ করে যম-নিয়মের সাধনাই আদর্শ সমাজের মূল ভিত্তি”। আনন্দমার্গের রাজাধিরাজ যোগ সাধনার ভিত্তি হ’ল নৈতিকতা। অষ্টাঙ্গ রাজাধিরাজ যোগসাধনায় যম, নিয়ম, আসন, প্রাণায়াম, প্রত্যাহার, ধারণা, ধ্যান ও সমাধি এর প্রথম দু’টি অর্থাৎ যম-নিয়ম হ’ল নৈতিক-সামাজিক আচরণ বিধি। এই যম-নিয়ম পালন না করলে যোগসাধনা হয় না। তাই আনন্দমার্গ যোগসাধনায় নৈতিক-সামাজিক আচরণবিধি পালনের ওপর সর্বাধিক গুরুত্ব দেওয়া হয়।
        </p> 
        <br /> 
        <b>সামাজিক চেতনা জাগ্রত করাঃ</b> <br />
        এক ও অদ্বিতীয়, অনাদি-অনন্ত, আনন্দঘন সত্তা পরমপুরুষ এই মহাবিশ্বের স্রষ্টা। এই পরিদৃশ্যমান জগৎ, মানুষ, পশু-পক্ষী-উদ্ভিদ সবই পরমপুরুষের মানস কল্পনায় সৃষ্ট। তিনি
        সবাইকার পিতা, বিশ্বপিতা। তিনি সবাইকার একান্ত আপন জন, পরম প্রিয় বাবা। আমরা সবাই তাঁর সন্তান, সবাই ভাই-বোন। সবাই এক বিশ্ব পরিবারের সদস্য। এই বিশ্বভ্রাতৃত্বের
        প্রেরণায় সবাইকে নিয়ে মিলেমিশে এক সঙ্গে এগিয়ে চলার নামই সমাজ। তাই শাস্ত্রে বলা হয়েছে ‘সমানম্‌ এজতে ইতি সমাজ' অর্থাৎ সকলে মিলেমিশে এক সংগে এগিয়ে চলার নাম সমাজ।
        <br />
        কিন্তু এই সামাজিক চেতনা ও নীতিবোধের অভাবের প্রতিফলন সমগ্র মানব সমাজে দেখা দিয়েছে। তাই সমাজে আজ প্রায় সর্বত্রই দুর্নীতি, অশান্তি ও নানান ধরণের ঘৃণ্য অপরাধ সংঘটিত
        হচ্ছে। জাত-পাত, ভাষা, সম্প্রদায় ইত্যাদি নানান ধরণের ভেদ-বিভেদে প্রায় গোটা সমাজটাই আজ দুষিত হয়ে উঠেছে। আজকের এই ঘুণেধরা সমাজের পরিবর্তন কেবল আইন ও প্রশাসনিক
        কঠোরতার দ্বারা সম্ভব নয়, তার সঙ্গে দরকার ব্যাপক নৈতিক ও সকল প্রকার সঙ্কীর্ণতা মুক্ত সামাজিক-আধ্যাত্মিক শিক্ষা। তাই চাই যোগের ব্যাপক চর্চা। <br />
        এখানে বলা বাহুল্য, যোগের সঙ্গে প্রচলিত ধর্ম-জাত-পাত-সম্প্রদায়-নারী-পুরুষের কোন সম্পর্ক নেই। যোগের আদর্শ বলে, ‘মানব সমাজ এক ও অবিভাজ্য’, ‘মানুষ মানুষ ভাই ভাই, উঁচু
        কিংবা নীচু নাই’। তাই সমস্ত সঙ্কীর্ণতার ঊর্ধ্বে উঠে সর্বত্র ব্যাপক যোগচর্চার দ্বারা এক সর্বাঙ্গসুন্দর মানব সমাজ গড়ে তোলা সম্ভব।
        <br /> <br />

        <b>আনন্দমার্গ যোগঃ</b> <br />
        <p className="indent-16">
          বিংশ শতাব্দীর মহান দার্শনিক, ধর্মগুরু যোগেশ্বর শ্রীশ্রীআনন্দমূর্ত্তিজী এই যোগসাধনাকে যুগোপযুগী করে সকল মানুষের শারীরিক-মানসিক-সামাজিক-নৈতিক-আধ্যাত্মিক উন্নতির
          জন্য প্রচার করেছেন। একে আনন্দমার্গ যোগ বা রাজাধিরাজ যোগ বলা হয়। আনন্দ মানে অনন্ত সুখ আর মার্গ মানে পথ। ‘তাই আনন্দমার্গ যোগ’ মানে হলো যে যোগ অনুশীলনের দ্বারা অনন্ত
          সুখ লাভ করা যায়। এই আনন্দমার্গ যোগসাধনাও অষ্টাঙ্গিক অর্থাৎ যম, নিয়ম, আসন, প্রাণায়াম, প্রত্যাহার, ধারণা, ধ্যান ও সমাধি। এর প্রথম দু’টি অঙ্গ হ’ল নীতিবাদ, শেষেরটি
          হ’ল লক্ষ্য আর বাকীগুলি হ’ল শারীরিক-মানসিক-আধ্যাত্মিক প্রয়াস (Physico-psycho-spiritual approach)। অর্থাৎ নীতিবাদে প্রতিষ্ঠিত হয়ে শারীরিক, মানসিক, আধ্যাত্মিক
          প্রয়াসের দ্বারা পরম লক্ষ্যে পৌঁছানোর চেষ্টাই হ’ল আনন্দমার্গ যোগ-সাধনা। <br />
        </p>
        ১) <b>যম-</b> যম মানে বাহ্যিক পরিবেশের পরিপ্রেক্ষিতে নিজেকে সংযত করা অর্থাৎ অপরের সঙ্গে সংযত ব্যবহার। মানুষ-পশু-পক্ষী-উদ্ভিদের সঙ্গে নিয়ন্ত্রিত ব্যবহার। যমের
        পাঁচটি অঙ্গ রয়েছে। যেমন- অহিংসা, সত্য, অস্তেয়, ব্রহ্মচর্য ও অপরিগ্রহ।
        <br /> 
        <ul className="list-disc list-outside ml-10">
          <li>
            <b>অহিংসাঃ</b> অহিংসা মানে হিংসা না করা। আর যখন কোন কথা, চিন্তা বা কাজ অন্য কাউকে দুঃখ বা কষ্ট দেবার জন্য বলা বা করা হয় তাকে হিংসা বলে। তাই মন, বাক্য ও শরীরের দ্বারা কারো ওপর পীড়ন না করার নামই অহিংসা।
          </li>
          <li>
            <b>সত্যঃ</b> অপরের কল্যাণের জন্যে, ভালোর জন্যে কিছু ভাবা ও সেই অনুসারে কিছু বলা বা করাকেই সত্য বলা হয়।{" "}
          </li>
          <li>
            <b>অস্তেয়ঃ</b> অস্তেয় মানে চুরি না করা। টাকা-পয়সা বা জিনিসপত্তর লুকিয়ে নিয়ে পালালে বা অস্ত্র-শস্ত্র নিয়ে ডাকাতি করে নিয়ে পালালেই যে কেবল চুরি হ'ল তা নয়। গায়ের জোরে, অস্ত্রের জোরে বা বুদ্ধির জোরে অন্যকে ঠকিয়ে বা পদমর্যাদার জোরে ঘুষ বা উপঢৌকনের মাধ্যমে অন্যের টাকা-পয়সা বা ধন-সম্পত্তি যা কিছু অপহরণ কর না কেন, তা চুরি পর্যায়ভূক্ত।
          </li>
          <li>
            <b>ব্রহ্মচর্যঃ</b> ‘ব্রহ্মচর্য' শব্দের প্রকৃত অর্থ হ'ল ব্রহ্মের ভাব নিয়ে কাজ করা। অর্থাৎ যে বস্তুকে বা জীবকে নিয়ে কাজ করছি বা চিন্তা করছি তাকে জড়বস্তু হিসাবে না দেখে ব্রহ্মেরই বিকাশ হিসাবে দেখা।
          </li>
          <li>
            <b>অপরিগ্রহঃ</b> নিজের তথা পরিবারের জন্য যতটা প্রয়োজন তার বেশী জড়বস্তুর ভোগের চেষ্টা না করাকে অপরিগ্রহ বলে।
          </li>
        </ul>
        ২) <b>নিয়ম-</b> নিয়ম মানে আত্মশুদ্ধির জন্য যে আচার-আচরণ। অর্থাৎ নিজেকে নিজের বহির্মুখী প্রবণতার বিরুদ্ধে সংযত করার প্রয়াস। নিয়মও পাঁচ প্রকার। যথা- শৌচ, সন্তোষ,
        স্বাধ্যায়, তপঃ ও ঈশ্বর প্রণিধান।
        <ul className="list-disc list-outside ml-10">
          <li>
            <b>শৌচঃ</b> শৌচ মানে পরিষ্কার-পরিচ্ছন্নতা। শৌচ দুই প্রকার। যথা- বাহ্যিক শৌচ ও মানসিক শৌচ। বাহ্যিক শৌচ মানে শরীর, জামা-কাপড়, ঘর ইত্যাদি পরিষ্কার-পরিচ্ছন্ন
            রাখা। মানসিক শৌচ মানে সব সময় অপরের ভাল চিন্তা করা। মনে মনেও কারো ক্ষতির চিন্তা না করা।
          </li>
          <li>
            <b>সন্তোষঃ</b> সহজ ভাবে যা পাওয়া যায় তাতে সন্তুষ্ট থাকার নামই সন্তোষ। অর্থাৎ শরীর ও মনের সামর্থের বেশী চাপ না দিয়ে যা উপার্জন করা যায় তাতে সন্তুষ্ট থাকাকেই
            সন্তোষ বলে।
          </li>
          <li>
            <b>তপঃ</b> উদ্দেশ্য সাধনের জন্য শারীরিক-মানসিক কষ্ট স্বীকার করাকে তপঃ বলে।
          </li>
          <li>
            <b>স্বাধ্যায়ঃ</b> অর্থ বুঝে ধর্মগ্রন্থ পাঠ করাকে স্বাধ্যায় বলে । সৎসঙ্গ, সাপ্তাহিক ধর্মচক্রে অংশগ্রহণ করাও স্বাধ্যায়ের অঙ্গ।
          </li>
          <li>
            <b>ঈশ্বর</b> প্রণিধানঃ সুখে-দুঃখে, সম্পদে-বিপদে ঈশ্বরে দৃঢ় বিশ্বাস রাখা ও জাগতিক সমস্ত ব্যাপারেই নিজেকে যন্ত্রী মনে না করে যন্ত্র মনে করে চলা।
          </li>
        </ul>
        ৩) <b>আসনঃ</b> নিয়মিত আসন অভ্যাসের দ্বারা অধিকাংশ ক্ষেত্রে বিভিন্ন রোগ থেকে দূরে থাকা যায় তথা সারিয়ে তুলতে পারা যায় আর গ্রন্থিগত ত্রুটি দূর করে জৈবিক বৃত্তির ওপর
        বহুলাংশে নিয়ন্ত্রণ আনা যায়। <br />
        ৪) <b>প্রাণায়ামঃ</b> আমাদের শরীর ও মন কাজ করে প্রাণশক্তির গতিধারার সঙ্গে সামঞ্জস্য রক্ষা করে। তাই এই প্রাণশক্তির ওপর নিয়ন্ত্রণ প্রয়োজন। প্রাণায়ামের দ্বারা
        প্রাণবায়ু নিয়ন্ত্রণের মাধ্যমে মনকে নিয়ন্ত্রণ করা যায় আবার অধিক বায়ু গ্রহণের মাধ্যমে অক্সিজেনের অভাব মিটিয়ে শারীরিক সুস্থ্যতা রক্ষা করা যায়। <br />
        ৫) <b>প্রত্যাহারঃ</b> বাহ্যিক বা কাল্পনিক বস্তুর প্রতি মানসিক আসক্তি কাটিয়ে ওঠার বা মানসিক আসক্তি নিয়ন্ত্রণের প্রয়াস। <br />
        ৬) <b>ধারণাঃ</b> মনকে একটা বিশেষ স্থানে বা ভাবে স্থিত করার প্রয়াস। <br />
        ৭) <b>ধ্যানঃ</b> আরাধ্যের ভাব নিয়ে তাঁর পানে মানসিক ভাবপ্রবাহকে নিরবচ্ছিন্ন ভাবে ছুটিয়ে দেবার প্রয়াস। <br />
        ৮) <b>সমাধিঃ</b> আরাধ্যের ভাবে একীভুত অবস্থা, চরম আনন্দঘন অবস্থায় সমাহিত হয়ে যাওয়া। <br />
      </p>

      <br />
     
      <b>যোগসাধনা (Meditation):</b> <br />
          <p className="indent-16">
          <img src={Yoga6} alt="Yoga six" className=" float-right w-fit md:w-2/6 mx-6"/>
          Meditation is a concentrated thinking- মেডিটেশন  হ’ল পূর্ণ মনোযোগের সাথে একটা বিশেষ চিন্তা করা। মানুষের মনের ধর্ম হচ্ছে As you think so you become অর্থাৎ যে যেমন চিন্তা করে সে তেমন হয়। মনের চিন্তাই তার কর্মের রূপ নেয়। কর্মের পরিণাম তার সংস্কারে পরিণত হয়। আবার সেই সংস্কার অনুযায়ী মানুষ চিন্তা করে ও কর্ম করে। তাই আমাদের চিন্তাকে নিয়ন্ত্রণ করে সঠিক পথে পরিচালিত করতে নিয়মিত যোগসাধনা করতে হবে।
          </p>
      <p>
        <br />
      <b>নিয়মিত যোগসাধনা (মেডিটেশন) অভ্যাস করলে, </b>
      <ol className="list-disc list-outside ml-5">
        <li>গভীর নিদ্রার মত শরীরকে দু'বার বিশ্রাম দেয়। ফলে শক্তি বৃদ্ধি পায়, উচ্চ রক্তচাপ কমে যায়।</li>
        <li>মানসিক চাপ জনিত (stress) রোগ সমূহ প্রতিরোধ করে ও সারিয়ে তুলতে সাহায্য করে।</li>
        <li>মানসিক অসন্তোষ, হতাশা, খিটখিটে ভাব, শত্রুতা ভাব ইত্যাদি কমে যায় ফলে মানসিক শান্তি লাভ করা যায়।</li>
        <li>মনের একাগ্রতা, দৃঢ়তা ও আত্মবিশ্বাস বেড়ে যায়। আর   যার মন যত একাগ্র সে তত মেধাবী।</li>
        <li>ছাত্র-যুবাদের জীবনের অশুভ প্রবণতা থেকে রক্ষা করে সঠিক পথে চলতে সাহায্য করে।</li>  
        <li>মন ও স্নায়ুকোষের সক্রিয় অংশ যথেষ্ট বিশ্রাম পায়। তাই শান্ত ও সুসংহত মনে কাজ করা বা যথাযথ সিদ্ধান্ত গ্রহণ করা সম্ভব হয়।</li>
        <li>এছাড়া মনের একাগ্রতার ফলে সহজে ক্লান্তিবোধ আসে না, ফলে কাজে উৎসাহ উদ্দীপনা বাড়ে।</li> 
        <li>চাকুরীজীবিদের কর্মক্ষেত্রে নানা রকম অশান্তি ও দুশ্চিন্তা থেকে রক্ষা করে।</li> 
        <li>নীতি পরায়নতা, বিশ্বস্ততা বাড়ায়।</li> 
        <li>ফলে চাকুরীজীবি, ব্যবসায়ী ইত্যাদি পেশাজীবিদের কর্মক্ষেত্রে দক্ষতা ও উৎপাদন ক্ষমতা বাড়ায়। একেই বর্তমান কালের বহু আলোচিত ভাষায় বলে ‘যোগাকোনমিক্স'।</li>
        <li>মনের বিস্তার হয় অর্থাৎ মন থেকে বিভিন্ন ধরণের সংকীর্ণতা, হীনন্মন্যতা বোধ ইত্যাদি চলে যায়। ফলে আধ্যাত্মিক চিন্তা, উচ্চতর গবেষণা, সৃজনশীলতা, সুস্থ্য সংস্কৃতির চর্চা ইত্যাদি সহজতর হয়।</li> 
        <li>আত্মনিয়ন্ত্রণ তথা আত্ম সন্তুষ্টি লাভ করা যায়।</li> 
        <li>পারিবারিক জীবনে হাসি-খুশী মেজাজে থাকা যায় ও শান্তিপূর্ণ ভাবে সকলকে মানিয়ে নিয়ে চলা সম্ভবপর হয়।</li>
      </ol>
      </p>
    </div>
  );
};

export default Yoga;
