import React from "react";

const AnandaMagrabd = () => {
  return (
    <div className="container mx-auto my-10 text-xl leading-10 h-screen">
      <h1 className="my-8 text-4xl font-bold">বাংলাদেশে আনন্দমার্গ</h1>
      <p className="text-justify leading-10 indent-16">
      ১৯৭১ সালে স্বাধীনতা যুদ্ধের সময় পশ্চিমবঙ্গ, আসাম ও ত্রিপুরার অনেক শরনার্থী শিবিরে আনন্দমার্গের সেবামূলক কাজ ও আদর্শে অনুপ্রাণীত হয়ে অনেকেই আনন্দমার্গের যোগসাধনা শেখেন। স্বাধীনতার পরে  ১৯৭২ সালে আনন্দমার্গের কেন্দ্রীয় আশ্রম কলিকাতা থেকে কয়েকজন সন্ন্যাসী প্রথম বাংলাদেশে আসেন ও তাদের দ্বারা অনুপ্রাণীত হয়েও অনেকে যোগসাধনা শেখেন। পরবর্ত্তী কালে তাদের মধ্যে কয়েকজন আনন্দমার্গের আদর্শ ও সেবামূলক কাজে অনুপ্রাণীত হয়ে সেবার ব্রতে সংকল্প নিয়ে সন্ন্যাস গ্রহণ করেন। ১৯৮৬ সালে <span className="font-bold">সালে “আনন্দমার্গ প্রচারক সংঘ”</span> বাংলাদেশ সরকার কর্তৃক নিবন্ধন লাভ করে। সংঘ সরকার কর্তৃক নিবন্ধিত হবার ফলে বাংলাদেশে সংঘের প্রচার ও সেবামূলক কার্যক্রম দ্রুতগতিতে এগিয়ে চলে। 
      </p>
    </div>
  );
};

export default AnandaMagrabd;
