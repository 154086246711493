import React from 'react';
import { Link } from 'react-router-dom';

import baba from '../../../img/Baba.jpg'
import logo from '../../../img/logo.png'


const HomeAbout = () => {
    return (
        <div>
            <h1 className='text-4xl font-bold text-center my-5'>আনন্দমার্গ সম্পর্কে</h1> 
            <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-4'>
                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>আনন্দমার্গের প্রতিষ্ঠাতা</h1>
                    <img src={baba} alt="Sree Probhate Ranjan Sarker" className='mx-auto' />
                    <p className='text-justify mb-3'>বিংশ শতাব্দীর মহান দার্শনিক, আনন্দমার্গের প্রবক্তা/প্রতিষ্ঠাতা শ্রীশ্রীআনন্দমূর্ত্তিজী ছিলেন একজন পরম শ্রদ্ধেয় ধর্মগুরু তথা সমাজগুরু ও বিশ্ব পরিব্যাপ্ত প্রতিষ্ঠান আনন্দমার্গ প্রচারক সংঘের সংস্থাপক ও পরিচালক। এছাড়াও তিনি ছিলেন একজন বিশ্ববন্দিত কালজয়ী সঙ্গীতগুরু, প্রাজ্ঞ শিক্ষাবিদ, সুসাহিত্যিক, অভিজ্ঞ ভাষাবিদ্, বিশিষ্ট ইতিহাসবেত্তা, প্রখ্যাত অর্থনীতিবিদ্ </p>
                    <Link to="establishment" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>
                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>আনন্দমার্গের লক্ষ্য ও আদর্শ</h1>
                    <img src={logo} alt="" className='w-56 mx-auto'/>
                    <p className='text-justify mb-3'>আনন্দমার্গ একটি পূর্ণাঙ্গ জীবনাদর্শ, একটি সর্বান্যুসুত আদর্শ জীবনধারা। আনন্দ মানে অনন্ত সুখ আর মার্গ মানে পথ। তাই আনন্দমার্গ মানে অনন্ত সুখের পথ। আনন্দমার্গ জীবনাদর্শের মূল কথা হলো ব্যষ্টি জীবনে আত্মমুক্তির জন্য অন্তর্মুখী মানস-আধ্যাত্মিক যোগ সাধনা করা আর সমাজ জীবনে জগতের কল্যাণের জন্য কাজ করে যাওয়া। এই আদর্শ বাস্তবায়নে </p>
                    <Link to="goal" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>
                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>বাংলাদেশে আনন্দমার্গ</h1>
                    <img src={logo} alt="" className='w-56 mx-auto'/>
                    <p className='text-justify mb-3'>১৯৭১ সালে স্বাধীনতা যুদ্ধের সময় পশ্চিমবঙ্গ, আসাম ও ত্রিপুরার অনেক শরনার্থী শিবিরে আনন্দমার্গের সেবামূলক কাজ ও আদর্শে অনুপ্রাণীত হয়ে অনেকেই আনন্দমার্গের যোগসাধনা শেখেন। স্বাধীনতার পরে ১৯৭২ সালে আনন্দমার্গের কেন্দ্রীয় আশ্রম কলিকাতা থেকে কয়েকজন সন্ন্যাসী প্রথম বাংলাদেশে আসেন ও তাদের দ্বারা অনুপ্রাণীত হয়েও অনেকে যোগসাধনা শেখেন।</p>
                    <Link to="ampsbd" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;