import React from 'react';
import { Link } from 'react-router-dom';
import {BsFacebook, BsYoutube} from 'react-icons/bs'



const Footer = () => {
    const year  = new Date();
        
    return (
        <div className='bg-black py-5'>
            <div className='md:flex px-10 py-3 text-white'>
                <div className='md:w-3/12 sm:w-6/12 w-full'>
                        Section 1
                </div>
                <div className='md:w-3/12 sm:w-6/12 w-full'>
                    <p className='text-2xl px-5 flex items-center gap-2 justify-center'> <BsFacebook />FaceBook</p>
                    <p className='text-2xl px-5 flex items-center gap-2 justify-center'> <BsYoutube /> Youtube</p>
                </div>
                <div className='md:w-3/12 sm:w-6/12 w-full'>
                    Section 3
                </div>
                <div className='md:w-3/12 sm:w-6/12 w-full'>
                    Section 4
                </div>
            </div>
            <div className='mt-5'>
                <p className='text-center'><span className='text-sm text-white'>Copyright &copy; {year.getFullYear()} Ananda Marga Pracaraka Samgha, Bangladesh; </span> <span className='text-xm text-white'><Link>Developing By <b className='text-blue-500'>Zibonanando Das</b></Link></span>  </p>
            </div>
        </div>
    );
};

export default Footer;