import React from 'react';

const ChildCare = () => {
    return (
        <div className='container mx-auto my-10 text-2xl text-justify h-screen leading-10'>

                <p className='text-5xl my-6'>আনন্দমার্গ শিশুসদন</p>

                <p className=''>আনন্দমার্গ শিশুসদন সারা বিশ্বে শত শত পিতৃমাতৃহীন, অসহায়-দুঃস্থ ছেলেমেয়েদের ভরণ-পোষণ তথা লেখাপড়ার সমস্ত দায়িত্ব নিয়ে আর্ত-মানবতার সেবা করে চলেছে। সেই সঙ্গে বাংলাদেশের বিভিন্ন স্থানে আমরাও এই ধরনের পিতৃমাতৃহীন, অসহায়-দুঃস্থ ছেলেমেয়েদের ভরণ-পোষণ তথা লেখাপড়ার দায়িত্ব নিয়ে  শিশুসদন স্থাপন ও পরিচালনা করে চলেছি।</p> <br />

                <ol className=''>
                    <li>আনন্দমার্গ শিশুসদন, সাদিশপুর, বদলগাছী, নওগাঁ।</li>
                    <li>আনন্দমার্গ শিশুসদন চাঁদপুর, পীরগঞ্জ, ঠাকুরগাঁও।</li>
                </ol>
                <br />
                <p>                                                             
                    “কর্মই মানুষকে মহান করে তোলে। সাধনার দ্বারা, সেবার দ্বারা ও ত্যাগের দ্বারা মহান হও”। <br />
                    =শ্রীশ্রীআনন্দমূর্ত্তিজী
                </p>
                <br />
                <p>
                “প্রত্যেক মানুষকেই তার জীবনকে সুন্দর ভাবে গড়ে তুলতে উৎসাহ দেবে। কেউ যেন কোন অবস্থাতেই ভাববার সুযোগ না পায় যে তার জীবন ব্যর্থ হয়ে গেছে”। <br />
                    -শ্রীশ্রীআনন্দমূর্ত্তিজী
                </p>
        </div>
    );
};

export default ChildCare;