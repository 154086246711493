import React from "react";

const Goal = () => {
  return (
    <div className="container mx-auto text-justify my-6 p-2 text-xl">
      <div className="my-5">
        <h1 className="text-3xl font-bold my-5 text-center">আনন্দমার্গের লক্ষ্য ও আদর্শঃ</h1>
        <p className="font-semibold leading-10 indent-16">
        আনন্দমার্গ একটি পূর্ণাঙ্গ জীবনাদর্শ, একটি সর্বান্যুসুত আদর্শ জীবনধারা। আনন্দ মানে অনন্ত সুখ আর মার্গ মানে পথ। তাই আনন্দমার্গ মানে অনন্ত সুখের পথ। আনন্দমার্গ জীবনাদর্শের মূল কথা হলো ব্যষ্টি জীবনে  আত্মমুক্তির জন্য  অন্তর্মুখী মানস-আধ্যাত্মিক যোগ সাধনা করা আর সমাজ জীবনে জগতের কল্যাণের জন্য কাজ করে যাওয়া। এই আদর্শ বাস্তবায়নে বাংলাদেশে আনন্দমার্গ নিম্নলিখিত ক্ষেত্রে কাজ করে চলেছে। 
        </p>
      </div>

      <div className="my-6 text-lg text-justify leading-9">
        <p className="text-xl font-bold bg-gray-200 py-5 pl-2">এই আদর্শ বাস্তবায়নে বাংলাদেশে আনন্দমার্গ নিম্নলিখিত ক্ষেত্রে কাজ করে চলেছে।</p>
        <h1 className="text-xl font-bold my-4">ব্যক্তিগত বিকাশঃ</h1>
        <ul className="list-disc list-outside ml-5">
          <li> জাতি, ধর্ম,বর্ণ,  লিঙ্গ নির্বিশেষে  সকল মানুষের শারীরিক, মানসিক, নৈতিক, আত্মিক  উন্নতির জন্য  অলাভজনক ভিত্তিতে  যোগ শিক্ষা কেন্দ্র / প্রতিষ্ঠান/ ইন্সটিটুট ইত্যাদি স্থাপন করে যোগ সাধনার গবেষণা, অনুশীলন, প্রচার ও প্রশিক্ষণ প্রদান করা।</li>
          <li>যৌগিক চিকিৎসাসহ বিকল্প চিকিৎসা যেমন  আয়ুর্বেদ, আকুপ্রেসার ইত্যাদি সেবা কেন্দ্র স্থাপন ও পরিচালনা  করা।</li>
        </ul>
      </div>
      <div className="mt-5">
        <h1 className=" text-xl font-bold mb-4">সেবামূলককার্যক্রমঃ</h1>
        <ul className="list-disc list-outside ml-5">
          <li>শিক্ষাই জাতির মেরুদন্ড। শিক্ষিত সমাজ গঠনে সু-শিক্ষা অপরিহার্য। বিধায় শিক্ষা সংক্রান্ত সকল কার্যক্রম গ্রহণ করা। *পিতৃ-মাতৃহীন, অসহায়, দুঃস্থ শিশুদের জন্য শিশুসদন (এতিমখানা ও লিল্লাহ বোর্ডিং), ছাত্রাবাস, বৃদ্ধাশ্রম ইত্যাদি স্থাপন ও পরিচালনা করা।</li>
          <li>প্রকৃতি সৃষ্ট বা কৃত্রিম দুর্যোগে স্থায়ী ও অস্থায়ীভাবে সহায়তা করা।</li>
          <li>বেকার যুবক-যুবতী বা শিক্ষারত ছাত্র-ছাত্রীদের আর্থিক উন্নয়নে কারিগরী প্রশিক্ষণ ও সহায়তার প্রয়াস করা।</li>
        </ul>
      </div>
      <div className="mt-5 text-lg text-justify leading-9">
        <h1 className="text-xl font-bold mb-4">সাংস্কৃতিক কার্য ক্রমঃ </h1>
        <ul className="list-disc list-outside ml-5">
          <li>
          অপ সংস্কৃতির বিরুদ্ধে জন সচেতনতা জাগ্রত করতে শিল্প প্রদর্শনী, সাংস্কৃতিক অনুষ্ঠান, সাংস্কৃতিক ও শিক্ষামূলক প্রতিযোগিতা, আর্ট গ্যালারী, নৃত্য-বাদ্য-সঙ্গীতের বিদ্যালয় স্থাপনা ইত্যাদি করা।
          </li>
        </ul>
      </div>
      <div className="mt-5 text-lg text-justify leading-9">
        <h1 className=" text-xl font-bold mb-4">প্রচারমূলক কার্যক্রমঃ</h1>
        <ul className="list-disc list-outside ml-5">
          <li>বিশ্বশান্তি তথা নব্য মানবতাবাদ আদর্শের প্রচার, প্রসারের জন্য পুস্তক-পত্রিকা প্রকাশনা, সেমিনার, সেম্পোজিয়াম, সন্মেলন, সভা, গ্রন্থাগার স্থাপনা ইত্যাদি করা।</li>
          <li>সমাজের নানা স্তরে বিভিন্ন ধরণের অন্ধবিশ্বাস, কুসংস্কার ও জাতিভেদ প্রথার বিরুদ্ধে প্রচার করে জনগনের বিচারপ্রবণ মানসিকতা জাগ্রত করা, মানবাধিকার সচেতন করা, মানবাধিকার রক্ষা করতে সাহায্য করা ও নব্য মানবতাবাদে উদ্বুদ্ধ করা।</li>
          <li>বিভিন্ন ধরণের মাদকদ্রব্যের বিরুদ্ধে গণচেতনা ও গণ আন্দোলন গড়ে মাদক মূক্ত সমাজ তৈরী করা।</li>
          <li>নারী নির্যাতন, স্ত্রী হত্যা, পণপ্রথা (যৌতুক), অনৈতিক বহুবিবাহ, অমানবিক বিবাহ বিচ্ছেদ ইত্যাদির বিরুদ্ধে জনসচেতনতা ও গণ আন্দোলন গড়ে তোলা।</li>
        </ul>
      </div>
      <div className="mt-5 text-lg text-justify leading-9">
        <h1 className="text-xl font-bold mb-4">সাংগঠনি ককার্যক্রমঃ</h1>
        <ul className="list-disc list-outside ml-5">
          <li>জেলা পর্যায়ে ভূক্তি প্রধান নির্বাচন করে ভূক্তি কমিটি গঠন করা। সেবা, প্রচার ও আর্থ সামাজিক কার্যক্রম বাস্তবায়ন করা। </li>
          <li>গ্রাম পর্যায়ে ইউনিট কমিটি গঠন করে যোগসাধনার অনুশীলন আর  সেবা ও প্রচার মূলক কার্যক্রম করা। </li>
        </ul>
      </div>
      <div className="mt-5 text-lg text-justify leading-9">
        <h1 className="text-xl font-bold mb-4"> বিবিধ কার্যক্রমঃ </h1>
        <ul className="list-disc list-outside ml-5">
          <li>সরকারি বিভিন্ন দিবস উদযাপন ও উন্নয়নমূলক কাজে সহযোগিতা ও অংশ গ্রহণ করা।  </li>
        </ul>
      </div>
    </div>
  );
};

export default Goal;
