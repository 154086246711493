import React from 'react';
import { Link } from 'react-router-dom';
import PROUT from'../../../img/Home/PROUTlogo.png'
import socialPhi from'../../../img/Home/ampssocio.jpg'
import philosophy from'../../../img/Home/philosophy.jpg'


const HomePhilosophy = () => {
    return (
        <div className='mt-10'>
            <h1 className='text-4xl font-bold text-center my-5'>আনন্দমার্গ দর্শন</h1> 
            <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-4'>
                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>অর্থনৈতিক দর্শন</h1>
                    <img src={PROUT} alt="" className='mx-auto w-[290px] mb-5'/>
                    <p className='text-justify mb-3'>ধনতান্ত্রিক বা পুঁজিবাদী সমাজ ব্যবস্থায় আর্থিক ক্ষেত্রে অবাধ স্বাধীনতা (Laissez Faire) নীতি অনুসৃত হবার ফলে কতিপয় মানুষ বুদ্ধির তথা পেশীর জোরে অপরকে শোষণ করে। আর এর অবশ্যম্ভাবী ফল হ’ল সমাজে শোষক আর শোষিতের সৃষ্টি। সমাজের এক শ্রেণীর মানুষের ঘরে সম্পদের পাহাড় রচিত হয় আর এর মাশুল হিসেবে কোটি কোটি মানুষকে অনাহারে তিলে তিলে শুকিয়ে মরতে হয়। মানবিক মূল্যের কথা ভাবলে </p>
                    <Link to="prout" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>
                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>সামাজিক দর্শন</h1>
                    <img src={socialPhi} alt="" className='mx-auto mb-2'/>
                    <p className='text-justify mb-3'> সবাই মিলে একসাথে চলার নামই সমাজ। সবাইকে এক সাথে নিয়ে চলার মধ্যেই সমাজের সার্থকতা। তাই সমাজের মধ্যে চলার একটা গতি থাকতেই হবে, থাকতে হবে একটা প্রারম্ভিক বিন্দু আর একটা লক্ষ্যস্থল। সমাজগুরু শ্রীপ্রভাতরঞ্জন সরকার বলেছেন- “নীতিবাদের স্ফুরণ থেকে বিশ্বমানবত্বের প্রতিষ্ঠা এ দু’য়ের মাঝখানে রয়েছে যেটুকু অবকাশ তাকে অতিক্রম করবার যে মিলিত প্রয়াস তারই নাম সামাজিক প্রগতি। </p>
                    <Link to="social" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md' >Read more .....</Link>
                </div>
                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>আধ্যাত্মিক দর্শন</h1>
                    <img src={philosophy} alt=""  className='mx-auto mb-2'/>
                    <p className='text-justify mb-3'>শ্রীশ্রীআনন্দমূর্ত্তিজী আনন্দসূত্রমে বলেছেন- “শিবশক্তাত্মকং ব্রহ্ম”। শিব ও শক্তির মিলিত নাম হচ্ছে ব্রহ্ম। এখানে শিব মানে হ’ল চৈতন্য সত্তা, আর শক্তি মানে হ’ল প্রকৃতি। প্রকৃতি মানে হ’ল প্রকার সর্জনকারিনী শক্তি। এই প্রকৃতি সত্ত্বঃ, রজঃ ও তমোঃ এই তিন গুণের সমাহার। এই শক্তি আবার শিবেরই শক্তি। যেমন- আগুন ও তার দাহিকা শক্তিকে আলাদা করা যায় না, তেমনি চৈতন্য সত্তা থেকেও প্রকৃতিকে আলাদা </p>
                    <Link to="spiritual" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>
            </div>
        </div>
    );
};

export default HomePhilosophy;