import React from "react";

const Amrt = () => {
  return (
    <div className="container mx-auto my-8 p-2 text-2xl text-justify h-screen">
      
      <h1 className="text-3xl font-bold my-10">আনন্দমার্গ ইউনিবার্সাল রিলিফি টীম (AMURT/AMURTEL) </h1>
      <p className="leading-loose">
      শ্রীশ্রীআনন্দমূর্ত্তিজী ছিলেন অত্যন্ত মানবদরদী। আর্ত্তের দুর্দশায় চিরদিনই প্রাণ কাঁদত তাঁর। খরা, বন্যা, ভূমিকম্পে ক্ষতিগ্রস্থ মানুষদের কথা চিন্তা করে তিনি ব্যথিত হয়ে পড়তেন। এই সব নিপীড়িত মানুষদের ত্রাণের জন্যে তিনি তৈরী করলেন আনন্দমার্গ ইয়ূনিবার্স্যাল রিলিফ টীম (AMURT/AMURTEL)।
      <br /> <br />
      
      1967 সাল থেকে ভারত সহ অন্যান্য দেশে প্রাকৃতিক ও মনুষ্য সৃষ্ট দুর্যোগে AMURT ও (মহিলা পরিচালিত) AMURTEL দুর্দম বেগে ত্রাণের কাজে ঝাঁপিয়ে পড়ার জন্য যাতে সদা প্রস্তুত থাকে সেই জন্য তিনি চাইতেন সর্বাধিক সংখ্যক দক্ষ  স্বেচ্ছাসেবী কর্মী যেন প্রশিক্ষণ প্রাপ্ত থাকে ও রিলিফ স্টোরে  যেন ত্রাণ সামগ্রী মজুদ থাকে। সংস্থাটির কাজে খুশী হয়ে  জাতিসংঘ এটিকে এখন স্বীকৃতি দিয়েছে।  <br /> <br />
      
      ১৯৮৫ সালে বাংলাদেশে আনন্দমার্গ প্রচারক সংঘ নিবন্ধন লাভ করার পর থেকে  আনন্দমার্গ ইয়ূনিবার্স্যাল রিলিফ টীম (AMURT/AMURTEL)  এ দেশেও বিভিন্ন স্থানে প্রাকৃতিক বা  মনুষ্য সৃষ্ট দুর্যোগে ত্রাণকার্য পরিচালনা করে চলেছে। সম্প্রতি ২০১৮ সালে আনন্দমার্গ ইয়ূনিবার্স্যাল রিলিফ টীম (AMURT) বাংলাদেশেও  বরিশাল জেলার সমাজসেবা অধিদপ্তর থেকে নিবন্ধন লাভ করেছে।

      </p>
    </div>
  );
};

export default Amrt;
