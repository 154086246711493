import React from 'react';
import { Link } from 'react-router-dom';
import Yoga1 from '../../../img/Home/Yoga1.jpg'
import EducActi from '../../../img/Home/Educationactivities.jpg'
import ChieldCare from '../../../img/Home/image004.jpg'
import RURAWA from '../../../img/Home/RU&RAWA.JPG'
import AMURT from '../../../img/Home/amurt.jpg'

const HomeActivities = () => {
    return (
        <div className='mt-10'>
            <h1 className='text-4xl font-bold text-center my-5'>আনন্দমার্গ কার্যক্রমসমূহ</h1> 

            <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-4'>
                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>আনন্দমার্গ যোগ- এক সন্তুলিত আদর্শ জীবনধারা</h1>
                    <img src={Yoga1} alt="" className='mx-auto'/>
                    <p className='text-justify mb-3'>যোগ মূলতঃ অত্যন্ত সূক্ষ্ম বিজ্ঞানের ওপর আধারিত একটি আধ্যাত্মিক জীবনধারা যা শরীর, মন ও আত্মার সমন্বয় আনতে গুরুত্ব দেয়। মানুষের অস্তিত্ব ত্রি-ভৌমিক- অর্থাৎ শরীর, মন ও আত্মা এই তিনকে নিয়ে মানুষের জীবন। তাই জীবনকে যথার্থ আনন্দময় করে গড়ে তুলতে হলে দৈহিক, মানসিক ও আত্মিক – এই তিনেরই উন্নতি একান্ত প্রয়োজন। আমাদের দৈহিক সুস্থতা  </p>
                    <Link to="yoga" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>
                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>আনন্দমার্গের শিক্ষা কার্যক্রম</h1>
                    <img src={EducActi} alt="" className='mx-auto'/>
                    <p className='text-justify mb-3'>শিক্ষা জাতির মেরুদণ্ড। কিন্তু দুঃখের সাথে বলতে হয় যে, আজকের পৃথিবীতে বিশেষতঃ বাংলাদেশে সবচেয়ে অবহেলিত হচ্ছে শিক্ষা ব্যবস্থা। কোনক্রমে কিছুটা পড়াশুনা করে একটা ডিগ্রী বা সার্টিফিকেট পাওয়াই যেন আজকের শিক্ষা ব্যবস্থা তথা ছাত্র সমাজের লক্ষ্য হয়ে দাঁড়িয়েছে। মানুষের নৈতিক তথা আত্মিক মূল্যবোধ সেখানে গৌন। এমতাবস্থায় আমাদের শিক্ষা ব্যবস্থার </p>
                    <Link to="education" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>
                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>আনন্দমার্গ শিশুসদন</h1>
                    <img src={ChieldCare} alt="" className='mx-auto'/>
                    <p className='text-justify mb-3'>আনন্দমার্গ শিশুসদন সারা বিশ্বে শত শত পিতৃমাতৃহীন, অসহায়-দুঃস্থ ছেলেমেয়েদের ভরণ-পোষণ তথা লেখাপড়ার সমস্ত দায়িত্ব নিয়ে আর্ত-মানবতার সেবা করে চলেছে। সেই সঙ্গে বাংলাদেশের বিভিন্ন স্থানে আমরাও এই ধরনের পিতৃমাতৃহীন, অসহায়-দুঃস্থ ছেলেমেয়েদের ভরণ-পোষণ তথা লেখাপড়ার দায়িত্ব নিয়ে শিশুসদন স্থাপন ও পরিচালনা করে চলেছি।</p>
                    <Link to="childrenhomes" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>

                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>আনন্দমার্গ ইউনিবার্সাল রিলিফি টীম </h1>
                    <img src={AMURT} alt="" className='mx-auto w-[240px]'/>                    <p className='text-justify mb-3'>শ্রীশ্রীআনন্দমূর্ত্তিজী ছিলেন অত্যন্ত মানবদরদী। আর্ত্তের দুর্দশায় চিরদিনই প্রাণ কাঁদত তাঁর। খরা, বন্যা, ভূমিকম্পে ক্ষতিগ্রস্থ মানুষদের কথা চিন্তা করে তিনি ব্যথিত হয়ে পড়তেন। এই সব নিপীড়িত মানুষদের ত্রাণের জন্যে তিনি তৈরী করলেন আনন্দমার্গ ইয়ূনিবার্স্যাল রিলিফ টীম (AMURT/AMURTEL)।</p>
                    <Link to="amrt" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>

                <div className='p-5 md:basis-1/3 border border-sky-500'>
                    <h1 className='text-xl font-bold mb-4 text-center'>RU & RAWA</h1>
                    <img src={RURAWA} alt="" className='mx-auto'/>
                    <p className='text-justify mb-3'>মানব অস্তিত্বের ত্রিস্তরে অর্থাৎ শারীরিক, মানসিক ও আত্মিক স্তরে আজ নেমে এসেছে এক চরম বিপর্যয়। মানব সমাজের বৃহত্তর জনগোষ্ঠী আজ নৈতিক অবক্ষয়, সামাজিক সচেতনতার অভাব, দারিদ্রতা, অন্ধ বিশ্বাস, কুসংস্কার তথা ভাবজড়তায় আবদ্ধ। ফলে জীবনের সর্বক্ষেত্রে – সামাজিক, অর্থনৈতিক, রাজনৈতিক, সাংস্কৃতিক, </p>
                    <Link to="rurawa" className='text-teal-700 font-bold border border-lime-600 p-2 rounded-md'>Read more .....</Link>
                </div>
            </div>
        </div>
    );
};

export default HomeActivities;