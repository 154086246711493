import React, { useState } from 'react';
import MySlider from './MySlider';
import {FcNext, FcPrevious} from 'react-icons/fc';



const Slider = () => {

    
  
  return (
    <div className="carousel">
      
    </div>
  );
};

export default Slider;